<template>
  <div class="ui-pagination ui-group">
    <ui-icon
      value="mdi:chevron-left"
      class="pagination-button --previous ui-clickable ui-button ui-noselect"
      :class="{'ui-disabled': page == 1}"
      @click="previous"
    ></ui-icon>

    <input
      type="number"
      min="1"
      class="pagination-page ui-native"
      :class="{'ui-disabled':  page == 1 && !hasNext}"
      :value="page"
      @input="$emit('update:page', $event.target.value || 1)"
      v-bind="$attrs"
    />

    <ui-icon
      value="mdi:chevron-right"
      class="pagination-button --next ui-clickable ui-button ui-noselect"
      :class="{'ui-disabled': !hasNext}"
      @click="next"
    ></ui-icon>
  </div>
</template>

<script>
import { UiItem, UiIcon } from '@/modules/ui/components';

export default {
  name: 'ui-pagination',
  components: { UiItem, UiIcon },

  props: {
    page: {
      type: [String, Number],
      required: false,
      default: 1,
    },

    totalPages: {
      type: [String, Number],
      required: false,
      default: null,
    },

    hasNext: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      foo: null,
    };
  },

  methods: {
    next() {
      if (isNaN(this.page)) {
        return this.$emit('update:page', 1);
      }
      this.$emit('update:page', parseInt(this.page) + 1);
    },

    previous() {
      if (isNaN(this.page)) {
        return this.$emit('update:page', 1);
      }
      this.$emit('update:page', Math.max(parseInt(this.page) - 1, 1));
    },
  },
};
</script>

<style lang="scss">
.ui-pagination {
  .pagination-page {
    width: 4em;
    text-align: center;
  }
}
</style>